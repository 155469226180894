/* eslint-disable react/jsx-props-no-spreading */
import '../../../../../components/Form/Form.css';

import { Button, PacmanLoader, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../../../common/api/api.accounts';
import type { ApiException } from '../../../../../common/api/api.types';
import { regex } from '../../../../../common/utils';
import DotVomEmailWarning from '../../../../../components/DotVomEmailWarning/DotVomEmailWarning';
import useLoginContext from '../../../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../../../hooks/useLoginNavigate';
import useExternalUser from '../../../useExternalUser';
import { useKimEmailQuery } from './hooks/useKimEmailQuery';

interface RegisterForm {
    email: string;
}

const RegisterEmail = () => {
    const context = useLoginContext();
    const { control, handleSubmit, formState, watch, setValue } = useForm<RegisterForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const navigate = useLoginNavigate();
    const { data: kimEmail, isLoading: kimEmailIsLoading } = useKimEmailQuery();

    const checkEmailMutation = useMutation<boolean, ApiException, string>({
        mutationFn: accountApi.isEmailInUse,
        onSuccess: (isEmailInUse, requestEmail) => {
            if (isEmailInUse === true) {
                navigate(
                    SpaRoutes.CreateAccount.Private.BankId.EmailAlreadyConnected(requestEmail),
                );
            } else {
                navigate(SpaRoutes.CreateAccount.Private.BankId.EmailNotConnected(requestEmail));
            }
        },
    });
    useEffect(() => {
        if (kimEmail) {
            setValue('email', kimEmail);
        }
    }, [kimEmail, setValue]);

    const { givenName } = useExternalUser(context.authState);

    const handleStartFormSubmit = (formData: RegisterForm) => {
        if (formState.isValid) {
            checkEmailMutation.mutate(formData.email);
        }
    };

    const isLoading = checkEmailMutation.isPending || kimEmailIsLoading;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                {givenName ? `Hej ${givenName}!` : 'Logga in'}
            </h1>
            <p className="u-textCenter">
                När du skapar ett nytt konto behöver du koppla det till en e-postadress. Du loggar
                sedan in enbart med BankID.
            </p>
            <p className="u-textCenter">
                {kimEmail ? 'Stämmer följande e-postadress?' : 'Ange din e-postadress: '}
            </p>

            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <div className="u-flex u-flexCenter">
                    <PacmanLoader className="u-marginT" isLoading={isLoading} />
                </div>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInput
                            className="u-marginTmd"
                            label="E-postadress"
                            isRequired
                            necessityIndicator="none"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                            autoFocus
                        />
                    )}
                    defaultValue={kimEmail || ''}
                    name="email"
                    disabled={isLoading}
                    rules={{
                        required: 'Ange e-postadress',
                        pattern: {
                            value: regex.email,
                            message: 'Angiven e-postadress har fel format.',
                        },
                    }}
                    control={control}
                />

                <DotVomEmailWarning value={watch('email')} />
                <footer className="u-marginTauto">
                    {checkEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {checkEmailMutation.error.friendlyMessage}
                        </p>
                    )}

                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="Button Button--green u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Gå vidare
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default RegisterEmail;
