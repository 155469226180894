/* eslint-disable react/jsx-props-no-spreading */
import { Button, Loader, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { getCoopseUrl, regex } from '../../../common/utils';
import InformationBox from '../../../components/InformationBox/InformationBox';
import { isUserAuthenticatedWithBankid } from '../../../core/authState.utils';
import useLoginContext from '../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';
import dispatchEvent from '../../../lib/dispatchEvent';

interface VerifyEmailForm {
    verificationCode: string;
}
const VerifyNewEmail = () => {
    const navigate = useLoginNavigate();
    const context = useLoginContext();
    const { email } = useParams();
    const { handleSubmit, formState, control } = useForm<VerifyEmailForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.sendChangeEmailVerificationCode,
    });

    const changeEmailMutation = useMutation<
        void,
        ApiException,
        { verificationCode: string; email: string }
    >({
        mutationFn: accountApi.changeEmailWithVerificationCode,
        onSuccess: () => {
            dispatchEvent('change-email-verification', true);
        },
        onError: (error) => {
            if (error.code === 'NO_EXTERNAL_SIGNIN') {
                context.refetchAuthState();
                navigate(SpaRoutes.ChangeEmail.Start);
            }
        },
    });

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const handleVerifyFormSubmit = (formData: VerifyEmailForm) => {
        if (formState.isValid && email) {
            changeEmailMutation.mutate({
                email,
                verificationCode: formData.verificationCode,
            });
        }
    };
    const isLoading = sendVerificationMailMutation.isPending || changeEmailMutation.isPending;

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
            changeEmailMutation.reset();
            setDisabledSendAgainButton(true);
        }
    };
    const hasMedmeraId = !!context.authState.currentUser?.medmeraId;
    if (changeEmailMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">E-postadress ändrad!</h1>

                {hasMedmeraId ? (
                    <p className="u-textCenter">
                        Din e-postadress är verifierad och din
                        <br />
                        e-postadress för inloggning och medlemskap har ändrats!
                    </p>
                ) : (
                    <p className="u-textCenter">
                        Din e-postadress är verifierad och din
                        <br />
                        e-postadress för inloggning har ändrats!
                    </p>
                )}

                <footer className="u-marginTauto">
                    {!isUserAuthenticatedWithBankid(context.authState) && (
                        <InformationBox text="Glöm inte att använda din nya e-postadress nästa gång du loggar in!" />
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        onClick={() => {
                            window.location.href = context.authState.redirectUrl || getCoopseUrl();
                        }}
                    >
                        Klar
                    </Button>
                </footer>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Bekräfta ny
                <br />
                e-postadress
            </h1>
            <p className="u-textCenter">
                Ange verifikationskoden som vi skickat till dig på e-postadressen{' '}
                <strong>{email}</strong>
            </p>
            <form className="Form" onSubmit={handleSubmit(handleVerifyFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInput
                            className="u-marginTxsm"
                            label="Verifikationskod"
                            isRequired
                            necessityIndicator="none"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    name="verificationCode"
                    rules={{
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    }}
                    defaultValue=""
                    control={control}
                />

                <footer className="u-marginTauto">
                    {isLoading && (
                        <div className="u-marginTmd">
                            <Loader />
                        </div>
                    )}
                    {changeEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {changeEmailMutation.error.friendlyMessage}
                        </p>
                    )}
                    {sendVerificationMailMutation.isError && (
                        <p className="Notice Notice--red">
                            {sendVerificationMailMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Verifiera
                    </Button>
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka igen
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default VerifyNewEmail;
