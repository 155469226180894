/* eslint-disable react/jsx-props-no-spreading */
import { Button, PasswordInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import useLoginContext from '../../../hooks/useLoginContext';
import { SpaRoutes } from '../../../hooks/useLoginNavigate';
import { useOpenIdQuery } from '../../../hooks/useOpenIdQuery';

export interface DeleteLoginformData {
    Password: string;
}

const RemoveAccountWithEmail = () => {
    const context = useLoginContext();
    const { authState } = context;
    const navigate = useNavigate();

    const { logoutId } = useOpenIdQuery();
    const { control, handleSubmit, formState } = useForm<DeleteLoginformData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const deleteAccountMutation = useMutation<
        void,
        ApiException,
        { email: string; password: string; logoutId?: string }
    >({
        mutationFn: async (params) => {
            const lid = await accountApi.removeAccountWithEmail({
                email: params.email,
                password: params.password,
                logoutId: params.logoutId,
            });
            await accountApi.signOutExternalScheme();
            navigate({
                pathname: SpaRoutes.Logout.Start,
                search: lid
                    ? `logoutId=${encodeURIComponent(lid)}&returnUrl=${
                          SpaRoutes.RemoveAccount.Done
                      }`
                    : `returnUrl=${SpaRoutes.RemoveAccount.Done}`,
            });
        },
        onSuccess: async () => {},
    });

    const handleDeleteLoginAccountFormSubmit = (formData: DeleteLoginformData) => {
        if (formState.isValid && authState?.currentUser?.email) {
            deleteAccountMutation.mutate({
                email: authState?.currentUser?.email,
                password: formData.Password,
                logoutId,
            });
        }
    };

    useEffect(() => {
        document.title = 'Coop | Radera kundkonto';
    }, []);
    const errorMessage = deleteAccountMutation.error?.friendlyMessage;

    if (deleteAccountMutation.isSuccess) {
        return (
            <p className="u-textCenter">
                Ditt kundkonto
                <br /> <strong className="u-textNoWrap">{authState?.currentUser?.email}</strong>
                <br /> har nu tagits bort från coop.se.
            </p>
        );
    }

    if (!authState?.currentUser?.email) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Ta bort kundkonto</h1>
                <p className="u-textCenter">För att radera ditt konto behöver du vara inloggad.</p>
                <NavLink
                    className="Button Button--green u-marginT"
                    to={{
                        pathname: SpaRoutes.Login.Start,
                        search: `returnUrl=${encodeURIComponent('/radera-kundkonto')}`,
                    }}
                >
                    Radera kundkonto
                </NavLink>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ta bort kundkonto</h1>

            <p>
                <strong>
                    Tänk på detta innan du tar bort ditt kundkonto till coop.se och coop-appen:
                </strong>
            </p>
            <ul>
                <li>
                    Beställningar och sparade varukorgar från e-handeln kommer inte vara
                    tillgängliga på webben via andra konton.
                </li>
                <li className="last">
                    Är du medlem kommer medlemskapet finnas kvar hos Coop och KF.
                </li>
            </ul>

            <p>
                Du är alltid välkommen att skapa ett nytt kundkonto för att handla online, eller som
                medlem ta del av alla medlemsförmåner.
            </p>

            <p>
                Vill du ta bort kundkontot <strong>{authState?.currentUser?.email}</strong>?
            </p>
            <form className="Form" onSubmit={handleSubmit(handleDeleteLoginAccountFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <PasswordInput
                            className="u-marginTmd"
                            label="Lösenord"
                            isRequired
                            necessityIndicator="none"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    name="Password"
                    disabled={deleteAccountMutation.isPending}
                    rules={{
                        required: 'Ange lösenord',
                    }}
                    defaultValue=""
                    control={control}
                />

                <footer className="u-marginTauto">
                    {deleteAccountMutation.isError && (
                        <p className="Notice Notice--red">{errorMessage}</p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || deleteAccountMutation.isPending}
                    >
                        Radera kundkonto
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default RemoveAccountWithEmail;
