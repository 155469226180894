/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox, PasswordInput, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { type SubmitHandler, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { accountApi } from '../../common/api/api.accounts';
import type { ApiException } from '../../common/api/api.types';
import { regex } from '../../common/utils';
import DotVomEmailWarning from '../../components/DotVomEmailWarning/DotVomEmailWarning';
import { useFormWithCustomValidator } from '../../hooks/useFormValidator';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';

interface LoginEmailProps {
    accountType: 'Private' | 'Company';
}
// Utility type to extract keys from the interface
const LoginEmail = (props: LoginEmailProps) => {
    const context = useLoginContext();

    const { handleSubmit, watch, control, validate, globalError } =
        useFormWithCustomValidator<FormDataEmail>(
            {
                mode: 'onChange',
                reValidateMode: 'onChange',
                shouldUnregister: true,
                defaultValues: { rememberMe: true },
            },
            ['email', 'password'],
        );

    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const signInMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            accountType: 'Private' | 'Company';
            rememberMe: boolean;
        }
    >({
        mutationFn: (p) =>
            accountApi.signinWithEmailPasswordToApplicationSchema({
                email: p.email,
                password: p.password,
                accountType: p.accountType,
                rememberMe: p.rememberMe,
            }),
        onError: (e) => {
            switch (e.code) {
                case 'USE_EXTERNAL_SIGNIN':
                    window.location.href = SpaRoutes.BuildChallengeExternalUrl(e.field, returnUrl);
                    break;
                default:
                    break;
            }
            validate(e);
        },
        onSuccess: () => {
            window.location.href = returnUrl;
        },
    });

    const handleUsernameFormSubmit: SubmitHandler<FormDataEmail> = (formData) => {
        if (isLoading) return;
        signInMutation.mutate({
            email: formData.email,
            password: formData.password,
            accountType: props.accountType,
            rememberMe: formData.rememberMe,
        });
    };

    const isLoading = signInMutation.isPending || signInMutation.isSuccess;

    return (
        <form className="Form" onSubmit={handleSubmit(handleUsernameFormSubmit)}>
            <Controller
                render={({ field, fieldState }) => (
                    <TextInput
                        className="u-marginTmd"
                        label="E-postadress"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="email"
                rules={{
                    required: 'Ange e-postadress',
                    pattern: {
                        value: regex.emailSpaceAtEnd,
                        message: 'Angiven e-postadress har fel format.',
                    },
                }}
                defaultValue=""
                control={control}
            />
            <DotVomEmailWarning value={watch('email')} />
            <Controller
                render={({ field, fieldState }) => (
                    <PasswordInput
                        className="u-marginTxsm"
                        label="Lösenord"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                defaultValue=""
                name="password"
                rules={{ required: 'Ange lösenord' }}
                control={control}
            />
            <Controller
                key="default"
                render={({ field }) => (
                    <Checkbox
                        id="remember-me"
                        name="rememberMe"
                        label="Kom ihåg mig"
                        className="u-marginTmd"
                        disabled={isLoading}
                        checked={field.value}
                        onBlur={field.onBlur}
                        onCheckedChange={field.onChange}
                    />
                )}
                name="rememberMe"
                control={control}
            />

            <footer className="u-marginTauto">
                {globalError && (
                    <p className="Notice Notice--red" role="alert" aria-atomic="true">
                        {globalError}
                    </p>
                )}

                <Button theme="primary" size={48} fullWidth className="u-marginT" type="submit">
                    Logga in
                </Button>

                <p className="u-textCenter u-marginT u-marginBz u-textSmall">
                    <Link
                        className="Link"
                        to={{
                            pathname:
                                props.accountType === 'Company'
                                    ? SpaRoutes.ForgotPassword.Company
                                    : SpaRoutes.ForgotPassword.Start,
                            search: window.location.search,
                        }}
                    >
                        Glömt lösenord?
                    </Link>
                </p>
                <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
                    {props.accountType === 'Company' ? (
                        <Link
                            className="Link"
                            to={{
                                pathname: SpaRoutes.CreateAccount.Company.Start,
                                search: window.location.search,
                            }}
                        >
                            Skapa ett företagskonto
                        </Link>
                    ) : (
                        <Link
                            className="Link"
                            to={{
                                pathname: SpaRoutes.CreateAccount.Private.Email.Start,
                                search: window.location.search,
                            }}
                        >
                            Skapa ett kundkonto
                        </Link>
                    )}
                </p>
                <EmployeeLogin
                    email={watch('email')}
                    returnUrl={context.authState.loginRequest?.returnUrl}
                />
            </footer>
        </form>
    );
};

const EmployeeLogin = (props: { email?: string; returnUrl?: string }) => {
    if (!props.email?.length || !props.returnUrl?.length) {
        return null;
    }
    if (!props.email.endsWith('@kf.se') && !props.email.endsWith('@coop.se')) {
        return null;
    }

    return (
        <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
            <a
                className="Link "
                href={`/externalauthentication/challange/CoopAzureAd?returnUrl=${encodeURIComponent(
                    props.returnUrl,
                )}`}
            >
                Logga in med Microsoft konto
            </a>
        </p>
    );
};

interface FormDataEmail {
    email: string;
    password: string;
    rememberMe: boolean;
}

export default LoginEmail;
