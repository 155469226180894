/* eslint-disable react/jsx-props-no-spreading */
import { Button, PasswordInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { isApiException } from '../../../common/api/api.utils';
import { getCoopseUrl, regex } from '../../../common/utils';
import { PasswordStrengthIndicator } from '../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { handleFormApiErrors } from '../../../hooks/useFormApiErrors';
import useLoginContext from '../../../hooks/useLoginContext';

interface ChangePasswordFields {
    OldPassword: string;
    Password: string;
    ConfirmPassword: string;
}

const ChangePasswordEmail = () => {
    const context = useLoginContext();
    const { formState, getValues, watch, setError, control } = useForm<ChangePasswordFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const changePassword = async () => {
        if (formState.isValid) {
            changePasswordMutation.mutate({
                oldPassword: getValues('OldPassword'),
                password: getValues('Password'),
                confirmPassword: getValues('ConfirmPassword'),
            });
        }
    };

    const changePasswordMutation = useMutation<
        void,
        ApiException,
        { oldPassword: string; password: string; confirmPassword: string }
    >({
        mutationFn: async (params) => {
            await accountApi.changePasswordWithEmail(params);
        },
        onSuccess: async () => {
            await accountApi.signOutExternalScheme();
        },
        onError: () => {
            context.refetchAuthState();
        },
    });

    useEffect(() => {
        // putting handleerror inside changePasswordMutation.error wont work since the input fields are disabled.
        if (isApiException(changePasswordMutation.error)) {
            handleFormApiErrors(changePasswordMutation.error, setError);
        }
    }, [changePasswordMutation.error, setError]);

    if (changePasswordMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Ändra lösenord</h1>
                <p className="u-textCenter u-marginTlg">
                    Ditt lösenord för{' '}
                    <strong className="u-textNoWrap">{context.authState.currentUser?.email}</strong>{' '}
                    är nu ändrat.
                </p>
                <p className="u-textCenter">Glöm inte att anteckna ditt lösenord.</p>
                <p className="u-textCenter">
                    <a
                        className="Link"
                        href={context.authState.redirectUrl || getCoopseUrl()}
                        rel="noreferrer"
                    >
                        Gå vidare till Coop.se
                    </a>
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ändra lösenord</h1>

            <p className="u-textCenter">
                Du ändrar lösenordet för inloggning med e-post{' '}
                <strong>{context.authState.currentUser?.email}</strong>
            </p>
            {changePasswordMutation.isError && (
                <p className="Notice Notice--red">{changePasswordMutation.error.friendlyMessage}</p>
            )}

            <Controller
                render={({ field, fieldState }) => (
                    <PasswordInput
                        className="u-marginTmd"
                        label="Nuvarande lösenord"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="OldPassword"
                disabled={changePasswordMutation.isPending}
                rules={{
                    required: 'Ange lösenord',
                }}
                defaultValue=""
                control={control}
            />
            <Controller
                render={({ field, fieldState }) => (
                    <PasswordInput
                        className="u-marginTxsm"
                        label="Nytt lösenord"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="Password"
                disabled={changePasswordMutation.isPending}
                rules={{
                    required: 'Ange lösenord',
                    pattern: {
                        value: regex.password,
                        message: 'Lösenordet måste bestå av minst 6 tecken.',
                    },
                }}
                defaultValue=""
                control={control}
            />
            <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />
            <Controller
                render={({ field, fieldState }) => (
                    <PasswordInput
                        label="Bekräfta nytt lösenord"
                        className="u-marginTxsm"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                disabled={changePasswordMutation.isPending}
                name="ConfirmPassword"
                rules={{
                    required: 'Ange lösenord',
                    validate: (value) =>
                        value === getValues('Password') || 'Lösenorden matchar inte varandra.',
                }}
                defaultValue=""
                control={control}
            />
            <footer className="u-marginTauto">
                <Button
                    theme="light"
                    size={48}
                    fullWidth
                    className="u-marginTmd"
                    disabled={
                        changePasswordMutation.isPending ||
                        changePasswordMutation.isSuccess ||
                        !formState.isValid
                    }
                    onClick={changePassword}
                >
                    Ändra lösenord
                </Button>
            </footer>
        </>
    );
};

export default ChangePasswordEmail;
