/* eslint-disable react/jsx-props-no-spreading */
import { CloseIcon, SearchIcon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { ChipIconButton } from '../Chip';
import { Icon } from '../Icon';
import styles from './Search.module.scss';

const SearchActions = (props: {
    inputValue?: string;
    submitLabel?: string;
    onEnterPress?: () => void;
    clearButtonRef?: React.RefObject<HTMLButtonElement>;
    clearButtonProps?: React.ComponentPropsWithoutRef<'button'>;
}) => {
    return (
        <div className={styles['Search-actions']}>
            {props.inputValue && props.clearButtonProps && props.clearButtonProps.onClick && (
                <ChipIconButton
                    ref={props.clearButtonRef}
                    icon={CloseIcon}
                    label="Rensa"
                    theme="whiteGreen"
                    size={24}
                    {...props.clearButtonProps}
                    onClick={props.clearButtonProps.onClick}
                />
            )}
            {props.inputValue && props.onEnterPress ? (
                <ChipIconButton
                    size={32}
                    theme="greenCta"
                    icon={SearchIcon}
                    onClick={props.onEnterPress}
                    label={props.submitLabel || 'Sök'}
                />
            ) : (
                <div aria-hidden className={classNames(styles['Search-icon'])}>
                    <Icon icon={SearchIcon} />
                </div>
            )}
        </div>
    );
};

export default SearchActions;
