import { splitCustomObjectFromIntersection } from '@coop/utils';
import React, { useState } from 'react';
import type { TextFieldProps } from 'react-aria-components';

import { ChipButton } from '../Chip';
import { TextField } from '../TextField';

interface PasswordInputCustomProps {
    label?: string;
    helperText?: string;
    validationText?: string;
    disabledReason?: React.ReactNode;
    /**
     * For more info see TextInput component.
     */
    necessityIndicator?: 'label' | 'none';
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputCustomProps & TextFieldProps>(
    (props, ref) => {
        const { custom: customProps, rest: textFieldProps } = splitCustomObjectFromIntersection<
            PasswordInputCustomProps,
            TextFieldProps
        >(props, {
            label: props.label,
            helperText: props.helperText,
            validationText: props.validationText,
            disabledReason: props.disabledReason,
            necessityIndicator: props.necessityIndicator,
        });

        const [type, setType] = useState<'password' | 'text'>('password');

        return (
            <TextField.Root
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...textFieldProps}
                // We mostly use the inputs with React Hook Form, so the validation behavior must be set to aria
                validationBehavior={textFieldProps.validationBehavior ?? 'aria'}
                type={type}
            >
                <TextField.TopContainer>
                    <TextField.Label
                        label={customProps.label}
                        isRequired={textFieldProps.isRequired}
                        necessityIndicator={customProps.necessityIndicator}
                    />
                    <TextField.HelperText
                        helperText={customProps.helperText}
                        disabledReason={customProps.disabledReason}
                    />
                </TextField.TopContainer>

                <TextField.InputContainer>
                    <TextField.Input ref={ref} />
                    <TextField.Postfix>
                        <ChipButton
                            theme="whiteBlack"
                            size={32}
                            onClick={() => {
                                setType((prevType) =>
                                    prevType === 'password' ? 'text' : 'password',
                                );
                            }}
                            aria-label={type === 'password' ? 'Visa lösenord' : 'Dölj lösenord'}
                        >
                            {type === 'password' ? 'Visa' : 'Dölj'}
                        </ChipButton>
                    </TextField.Postfix>
                </TextField.InputContainer>

                {customProps.validationText && (
                    <TextField.BottomContainer>
                        <TextField.ValidationError validationText={customProps.validationText} />
                    </TextField.BottomContainer>
                )}
            </TextField.Root>
        );
    },
);

export default PasswordInput;
