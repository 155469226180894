/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox, PasswordInput, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { type IdentityProviderDto, accountApi } from '../../common/api/api.accounts';
import type { ApiException } from '../../common/api/api.types';
import { regex } from '../../common/utils';
import AppContext from '../../context/appContext';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';

interface FormLogin {
    Email: string;
    Password: string;
    RememberMe: boolean;
}
interface FormEmail {
    ValidateEmail: string;
}
/*
https://loginlocal.coop.se:5001/logga-in?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dkommunal-admin-local%26redirect_uri%3Dhttp%253A%252F%252Flocalhost:3000%252Fcallback%26response_type%3Dcode%26scope%3Dopenid%2520hybris_api%2520hybris.profile%26state%3D129441ffd40445b8a50d4002d86b2734%26code_challenge%3DG_BHpd9XtCub5fyTy0i-UTPPCBKgDVT6-lVqSea3N1Q%26code_challenge_method%3DS256%26response_mode%3Dquery%26LoginType%3Dmunicipal
*/
const LoginOmbudshandel = () => {
    const context = React.useContext(AppContext);
    const [defaultEmail, setDefaultEmail] = React.useState<string>('');
    const [isEmailValidated, setIsEmailValidated] = React.useState(false);
    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Logga in
                <br />
                ombudshandel
            </h1>
            {!isEmailValidated && (
                <SelectEmailStep
                    onEmailValidated={(email) => {
                        setIsEmailValidated(true);
                        setDefaultEmail(email);
                    }}
                    email={defaultEmail}
                />
            )}
            {isEmailValidated && (
                <LoginPasswordStep
                    onEmailChanged={(email) => {
                        setDefaultEmail(email);
                        setIsEmailValidated(false);
                    }}
                    email={defaultEmail}
                />
            )}
        </>
    );
};

const SelectEmailStep = (props: { email: string; onEmailValidated: (email: string) => void }) => {
    const { handleSubmit, formState, watch, control } = useForm<FormEmail>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: false,
    });

    const context = useLoginContext();
    const email = watch('ValidateEmail');
    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const checkExternalProviderMutation = useMutation<
        IdentityProviderDto | undefined,
        ApiException,
        {
            email: string;
        }
    >({
        mutationFn: (form) =>
            accountApi.getIdentityProviderForEmail({
                email: form.email,
            }),
        onSuccess: (data) => {
            if (!data) {
                props.onEmailValidated(email);
            }
        },
    });
    const handleEmailFormSubmit = (formData: FormEmail) => {
        if (formState.isValid) {
            if (!hasValidatedExternalProvider) {
                checkExternalProviderMutation.mutate({ email: formData.ValidateEmail });
            }
        }
    };

    useEffect(() => {
        if (
            email &&
            checkExternalProviderMutation.submittedAt &&
            checkExternalProviderMutation.variables?.email !== email
        ) {
            checkExternalProviderMutation.reset();
        }
    }, [checkExternalProviderMutation, email]);

    const isLoading = checkExternalProviderMutation.isPending;
    const hasExternalProvider = !!checkExternalProviderMutation.data;
    const hasValidatedExternalProvider = checkExternalProviderMutation.isSuccess;
    const errorCode = checkExternalProviderMutation.error?.code;
    return (
        <form className="Form" onSubmit={handleSubmit(handleEmailFormSubmit)}>
            <Controller
                render={({ field, fieldState }) => (
                    <TextInput
                        className="u-marginTmd"
                        label="E-postadress"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                        autoFocus
                    />
                )}
                defaultValue={props.email}
                name="ValidateEmail"
                rules={{
                    required: 'Ange e-postadress',
                    pattern: {
                        value: regex.email,
                        message: 'Angiven e-postadress har fel format',
                    },
                }}
                control={control}
            />
            <footer className="u-marginTauto">
                {errorCode === 'INVALID_EMAIL' && (
                    <p className="Notice Notice--red">
                        Felaktig e-postadress. Vänligen kontrollera att du har skrivit in den
                        korrekt och att det inte finns några mellanslag i e-postfältet.
                    </p>
                )}
                {!hasExternalProvider && (
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        disabled={!formState.isValid || isLoading}
                        type="submit"
                    >
                        Fortsätt
                    </Button>
                )}
                {hasExternalProvider && (
                    <>
                        <p>
                            Din e-post {email} är kopplad till{' '}
                            {checkExternalProviderMutation.data?.name}.
                        </p>
                        <Button
                            theme="primary"
                            size={48}
                            fullWidth
                            className="u-marginT"
                            disabled={!formState.isValid || isLoading}
                            type="button"
                            onClick={() => {
                                if (checkExternalProviderMutation.data?.scheme) {
                                    window.location.href = SpaRoutes.BuildChallengeExternalUrl(
                                        checkExternalProviderMutation.data?.scheme,
                                        returnUrl,
                                    );
                                }
                            }}
                        >
                            Logga in via {checkExternalProviderMutation.data?.name}
                        </Button>
                    </>
                )}
            </footer>
        </form>
    );
};

const LoginPasswordStep = (props: { email: string; onEmailChanged: (email: string) => void }) => {
    const { handleSubmit, formState, control } = useForm<FormLogin>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: false,
    });
    const context = useLoginContext();

    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const signInMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            rememberMe: boolean;
        }
    >({
        mutationFn: (funcProps) =>
            accountApi.signinWithEmailPasswordToApplicationSchema({
                email: funcProps.email,
                password: funcProps.password,
                accountType: 'Both',
                rememberMe: funcProps.rememberMe,
            }),
        onSuccess: () => {
            window.location.href = returnUrl;
        },
    });

    const handleUsernameFormSubmit = (formData: FormLogin) => {
        if (formState.isValid) {
            signInMutation.mutate({
                email: formData.Email,
                password: formData.Password,
                rememberMe: formData.RememberMe,
            });
        }
    };
    const isLoading = signInMutation.isPending || signInMutation.isSuccess;
    return (
        <form className="Form" onSubmit={handleSubmit(handleUsernameFormSubmit)}>
            <Controller
                render={({ field, fieldState }) => (
                    <TextInput
                        className="u-marginTmd"
                        label="E-postadress"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                        autoFocus
                    />
                )}
                defaultValue={props.email}
                name="Email"
                disabled={isLoading}
                rules={{
                    onChange: (e) => {
                        props.onEmailChanged(e.target.value);
                    },
                    required: 'Ange e-postadress',
                    pattern: {
                        value: regex.email,
                        message: 'Angiven e-postadress har fel format',
                    },
                }}
                control={control}
            />

            <Controller
                render={({ field, fieldState }) => (
                    <PasswordInput
                        className="u-marginTxsm"
                        label="Lösenord"
                        isRequired
                        necessityIndicator="none"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                        autoFocus
                    />
                )}
                name="Password"
                disabled={isLoading}
                rules={{ required: 'Ange lösenord' }}
                control={control}
            />

            <Controller
                render={({ field }) => (
                    <Checkbox
                        className="u-marginTxsm"
                        id="remember-me"
                        name="RememberMe"
                        label="Kom ihåg mig"
                        disabled={isLoading}
                        checked={field.value}
                        onBlur={field.onBlur}
                        onCheckedChange={field.onChange}
                    />
                )}
                name="RememberMe"
                control={control}
            />

            <footer className="u-marginTauto">
                {signInMutation.isError && (
                    <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                )}
                <Button
                    theme="primary"
                    size={48}
                    fullWidth
                    className="Button Button--green u-marginT"
                    disabled={!formState.isValid || isLoading}
                    type="submit"
                >
                    Logga in
                </Button>

                <p className="u-textCenter u-marginT u-marginBz u-textSmall">
                    <Link
                        className="Link"
                        to={{
                            pathname: '/glomt-losenord',
                            search: window.location.search,
                        }}
                    >
                        Glömt lösenord?
                    </Link>
                </p>
            </footer>
        </form>
    );
};

export default LoginOmbudshandel;
