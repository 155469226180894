/* eslint-disable react/jsx-props-no-spreading */
import '../../components/Link/Link.css';
import '../../components/Form/Form.css';
import '../../components/Notice/Notice.css';

import { Button, TextInput } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { accountApi } from '../../common/api/api.accounts';
import type { ApiException } from '../../common/api/api.types';
import { regex } from '../../common/utils';
import DotVomEmailWarning from '../../components/DotVomEmailWarning/DotVomEmailWarning';
import useLoginContext from '../../hooks/useLoginContext';
import { buildUrlWithParams, SpaRoutes } from '../../hooks/useLoginNavigate';

type FormData = { Email: string };
const ForgotPassword = () => {
    const context = useLoginContext();
    const location = useLocation();
    const routePath = useParams<{ type: string }>();

    const { handleSubmit, formState, watch, control } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const [sentEmail, setSentEmail] = useState<string>();
    const forgotPasswordMutation = useMutation<void, ApiException, { email: string }>({
        mutationFn: accountApi.sendForgotPasswordEmail,
    });

    const handleForgotPasswordFormSubmit = (formData: FormData) => {
        if (formState.isValid) {
            forgotPasswordMutation.mutate({ email: formData.Email });
            setSentEmail(formData.Email);
        }
    };

    React.useEffect(() => {
        document.title = 'Coop | Glömt lösenord';
    }, []);

    React.useEffect(() => {
        context?.setShowNavigation(true);
        if (routePath.type === 'foretag') {
            context?.setBackUrl(buildUrlWithParams(SpaRoutes.Login.Company, location.search));
        } else {
            context?.setBackUrl(buildUrlWithParams(SpaRoutes.Login.Start, location.search));
        }
    }, [context, routePath.type, location.search]);

    if (forgotPasswordMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Glömt lösenord?</h1>

                <p className="u-textCenter">
                    Vi har skickat ett e-postmeddelande till{' '}
                    <strong className="u-textNoWrap">{sentEmail}</strong> med information om hur du
                    återställer ditt lösenord.
                </p>
                <p className="u-textCenter">
                    Kommer du inte åt inboxen eller har något annat problem,{' '}
                    <a
                        className="Link"
                        href={
                            routePath.type === 'foretag'
                                ? 'https://www.coop.se/foretag/kundservice/'
                                : 'https://www.coop.se/kundservice/'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        kontakta Kundservice
                    </a>
                    .
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Glömt lösenord?</h1>

            <p className="u-textCenter">
                Fyll i din e-postadress så skickar vi en länk för att återställa ditt lösenord.
                Länken är giltig i 24 timmar.
            </p>
            <form className="Form" onSubmit={handleSubmit(handleForgotPasswordFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInput
                            className="u-marginTmd"
                            label="E-postadress"
                            isRequired
                            necessityIndicator="none"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="Email"
                    rules={{
                        required: 'Ange e-postadress',
                        pattern: {
                            value: regex.emailSpaceAtEnd,
                            message: 'Angiven e-postadress har fel format',
                        },
                    }}
                    control={control}
                />
                <DotVomEmailWarning value={watch('Email')} />
                <footer className="u-marginTauto">
                    {forgotPasswordMutation.isError && (
                        <p className="Notice Notice--red">
                            {forgotPasswordMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || forgotPasswordMutation.isPending}
                    >
                        Återställ lösenord
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default ForgotPassword;
