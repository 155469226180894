import { useCallback, useState } from 'react';
import {
    type FieldPath,
    type FieldValues,
    type SubmitErrorHandler,
    type SubmitHandler,
    type UseFormProps,
    useForm,
} from 'react-hook-form';

import type { ApiException } from '../common/api/api.types';

export const useFormWithCustomValidator = <TFieldValues extends FieldValues = FieldValues>(
    props: UseFormProps<TFieldValues>,
    validateFields: FieldPath<TFieldValues>[],
) => {
    const [globalError, setGlobalError] = useState<string | null>(null);
    const { setError, handleSubmit, ...form } = useForm<TFieldValues>(props);
    const validate = useCallback(
        (exception: ApiException) => {
            if (!exception.friendlyMessage) {
                return;
            }

            const key = validateFields.find((k) => k === exception.field);
            if (key) {
                setError(
                    key,
                    { type: 'manual', message: exception.friendlyMessage },
                    { shouldFocus: true },
                );
            } else {
                setGlobalError(exception.friendlyMessage);
            }
        },
        [validateFields, setError, setGlobalError],
    );

    // always clear error before submit
    const clearGlobalErrorSubmit = useCallback(
        (
            onValid: SubmitHandler<TFieldValues>,
            onInvalid?: SubmitErrorHandler<TFieldValues> | undefined,
        ) => {
            return handleSubmit((data, event) => {
                setGlobalError(null);
                return onValid(data, event);
            }, onInvalid);
        },
        [handleSubmit],
    );
    return { validate, handleSubmit: clearGlobalErrorSubmit, ...form, setError, globalError };
};
